.form {
  display: flex;
  // margin-bottom: rem(50px);
  background-color: white;
  padding: 55px;
  margin-left: 100px;
  padding-right: 20vw;
  z-index: 1;  
  position: relative;


  :focus{outline: none;}

  &__row {
    position: relative;
    margin-top: 15px;
    border: 1px solid #C1BBA9;
    padding-top: 20px;
  }

  &__input {
    margin-top: 5px;
    padding: 9px 12px;
    border: 0;
    background-color: transparent;
    position: relative;
    font-size: 1.6rem;
    width: 100%;
    color: #4F647C;
    &--checkbox{
      margin: 0 5px 0 0 ;
      display: none;

      &:checked + label .fakebox {
        background-color:#4f647c56;
      }
    }
    &--textarea{
      margin-bottom: -7px;
    }

    // & ~ .form__border {
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 0;
    //   height: 2px;
    //   background-color: $color-main;
    //   transition: 0.3s;
    // }

    // &:focus ~ .form__border {
    //   width: 100%;
    //   transition: 0.3s;
    // }

    &:focus ~ .form__label {
      top: 5px;
      font-size: 12px;
      color: #4F647C;
      transition: 0.3s;
    }

    &--has-content ~ .form__border {
      width: 100%;
      transition: 0.4s;
    }

    &--has-content ~ .form__label {
      top: 5px;
      font-size: 12px;
      color: #4F647C;
      transition: 0.3s;
    }

  }

  &__label  {
    position: absolute;
    left: 15px;
    width: 100%;
    top: 40px;
    color: #4F647C;
    transition: 0.3s;
    letter-spacing: 0.5px;
    display: block;
    margin-bottom: 15px;
    font-size: rem(17px);
  }

  &__rodo {
    font-size: rem(14px);
    width: 100%;
    margin-bottom: rem(30px);
    margin-top: rem(15px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    label, input{
      cursor: pointer;
    }

    label {
      display: flex;
      align-items: center;
    }
  }

  select {
    background-color: unset;
  }

  option {
    margin: 5px;
  }

  &> div {
    margin-right: 15px;
    width: 500px;

    &:first-child {
      &> div {
        &:last-child {
          height: calc(100% - 108px);
        }
      }
    }

    @include mq($until: xl) {
      width: 430px;

      &:first-child {
        &> div {
          &:last-child {
            height: calc(100% - 105px);
          }
        }
      }
    }
  }

  @include mq($until: xl) {
    margin-left: 0;
    padding: 35px 160px 35px 35px;
  }

  @include mq($until: lg) {
    padding: 35px 40vw 35px 35px;
    flex-direction: column;
  }

  @include mq($until: md) {
    padding: 30px;

    &> div {
      margin-right: 0;
      width: 100%;
    }
  }

  @include mq($until: xs) {
    padding: 15px;
  }
}

#topic {
  font-size: rem(18px);
}

.close {
  &:hover {
    cursor: pointer;
  }
}

.alert{
  h4{
    margin-top: 15px;
    font-size: rem(20px);
  }
}

.contact-form {
  background-color: #C1BBA9;
  padding-top: 100px;
  padding-bottom: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  h2 {
    color: white;
    margin-bottom: 50px;
    text-transform: initial;
    margin-left: 100px;
    font-size: rem(35px);
  }

  &:after {
    content: 'polna';
    position: absolute;
    font-size: 20vw;
    color: rgba(255, 255, 255, 0.096);
    bottom: 3%;
    font-family: $font-family;
    text-transform: uppercase;
    right: 2%;
  }

  &> div {
    position: relative;

    &> p {
      box-shadow: 0px 0px 21px #00000029;
      position: absolute;
      height: auto;

      &:first-of-type {
        width: 50%;
        top: 0;
        right: -32%;
        z-index: 2;
      }

      &:last-of-type {
        width: 46%;
        bottom: -24%;
        left: -32%;
        z-index: 0;
      }

      @include mq($until: xl) {
        &:first-of-type {
          width: 69%;
          right: -57%;
        }
      }

      @include mq($until: lg) {
        &:first-of-type {
          width: 90%;
          right: -50%;
          top: 10%;
        }

        &:last-of-type {
          display: none;
        }
      }

      @include mq($until: md) {
        &:first-of-type {
          display: none;
        }
      }
    }
  }

  @include mq($until: xl) {
    justify-content: flex-start;
    padding-top: 75px;
    padding-bottom: 75px;

    h2 {
      margin-left: 0;
      margin-bottom: 30px;
    }
  }

  @include mq($until: md) {
    padding-top: 50px;
    padding-bottom: 50px;

    h2 {
      font-size: rem(30px);
    }
  }
}

.fakebox {
  width: 40px;
  height: 40px;
  border: 1px solid #C1BBA9;
  display: block;
  transition: all .3s;

  &+ span {
    width: 89%;
    margin-left: 15px;
  }

  @include mq($until: xl) {
    &+ span {
      font-size: rem(13px);
      color: #4F647C;
    }
    width: 30px;
    height: 30px;
  }
}
