.first{
    position: relative; 
    &__image{
        img {
            height: 100vh;
            object-fit: cover;
        }
    }
    h1 {
        position: absolute;
        top:6vw;
        color: #fff;
        max-width: 35%;
        font-size: 3vw;
        right: $padding-xxl;
        text-align: right;
        text-shadow: 0px 0 4px black;
    }

    @include mq($until: lg) {
        h1 {
            right: $padding-lg;
            font-size: 4.2vw;
            max-width: 50%;
        }
    }

    @include mq($until: sm) {
        h1 {
            right: $padding-sm;
            font-size: 4.2vw;
            max-width: 50%;
            top:70px;
        }

        img {
            height: 300px;
            object-fit: cover;
        }
    }

    @include mq($until: xs) {
        h1 {
            right: $padding-xs;
            font-size: 6vw;
            max-width: 70%;
        }
    }
}
.icons-bar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 5vw;
    &__item{
        padding-top: 3vw;
        padding-bottom: 3vw;
        max-width: 15%;
        min-width: 180px;
        display: flex;
        align-items: center;
        flex-direction: column;
        svg{
            width: 5vw;
            @include mq($until: md){
                width: 8vw;
            }
            @include mq($until: sm){
                width: 13vw;
            }
        }
        @include mq($until: md){
            min-width: 40%;
        }
        p{
            text-align: center;
            padding-top: 21px;
            @include mq($until: md){
                font-size: rem(15px);
            }
            @include mq($until: xs){
                font-size: rem(21px);
            }
        }
    }
}
.second{
padding-bottom: 13vw;
    &__content{
        display: flex;
        align-items: center;
        position: relative;
        display: flex;
        align-items: center;
        z-index: 2;
        @include mq($until: md){
           flex-direction: column;
           align-items: row;
        }
    }
    &__text{
        padding-left:3vw;
        max-width: 28%;
        position: relative;
        h2, p{
            color: #fff;
            line-height: 1.3;
        }

        p {
            line-height: 1.7;
        }
        @include mq($until: md){
            max-width: 70%;
            padding-top: 34px;
        }
        @include mq($until: sm){
            padding-left:8vw;
        }
        @include mq($until: xs){
            max-width: 80%;
        }
    }
    &__photo{
        width: 100%;
        &> p {
          width: 100%;  
        }
        @include mq($until: md){
            max-width: 90%;
            margin-right: auto;
        }
        @include mq($until: xs){
            max-width: 95%;
        }
    }
    &__bg-color{
        position: absolute;
        background-color: #96A480;
        height: 120%;
        width: 90%;  
        right: 0;
        z-index: -1;
        overflow:hidden;
        @include mq($until: md){
            height: 80%;
            bottom: -10%;
         }
    }
    &__color-element{
        position: relative;
        z-index: 1;
        color: #c7d4b1;
        font-size: 400px;
        opacity: .2;
        right: -10%;
        bottom: -34%;
        font-family: $font-family;
        @include mq($until: lg){
            font-size: 300px;
            bottom: -45%;
        }
        @include mq($until: md){
            font-size: 34vw;
            bottom: -30%;
        }
        @include mq($until: sm){
            bottom: -30%;
        }
        @include mq($until: xs){
            bottom: -50%;
        }
    }
}

.third{
padding-bottom: 13vw;
    &__content{
        position: relative;
        display: flex;
        align-items: center;
        z-index: 2;
        @include mq($until: md){
           flex-direction: column;
           align-items: row;
        }
    }
    &__text{
        padding-right:3vw;
        max-width: 28%;
        position: relative;
        h2, p{
            color: #fff;
            line-height: 1.3;
        }
        p {
            line-height: 1.7;
        }
        @include mq($until: md){
            max-width: 80%;
            padding-top: 34px;
            padding-bottom: 34px;
            padding-right: 34px;
        }
        @include mq($until: sm){
            padding-left:8vw;
        }
        @include mq($until: xs){
            max-width: 86%;
            padding-left:0;
        }
    }
    &__photo{
        @include mq($until: md){
            max-width: 90%;
            margin-left: auto;
        }
        @include mq($until: xs){
            max-width: 93%;
        }
    }
    &__bg-color{
        position: absolute;
        background-color: #4F647C;
        height: 120%;
        width: 90%;  
        left: 0;
        z-index: -1;
        overflow:hidden;
        @include mq($until: md){
            height: 80%;
        }
    }

    &__color-element{
        position: relative;
        z-index: 1;
        color: #7996b8;
        font-size: 400px;
        opacity: .2;
        left: -10%;
        bottom: -34%;
        font-family: $font-family;
        @include mq($until: lg){
            font-size: 300px;
            bottom: -45%;
        }
        @include mq($until: md){
            font-size: 400px;
            bottom: -30%;
        }
        @include mq($until: sm){
            font-size: 300px;
            bottom: -20%;
        }
    }

    @include mq($until: xs) {
        margin-top: 30px;
    }
}

.fourth{
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 8vw;
    &--second{
        @include mq($until: md){
            flex-direction: column-reverse;
        }
    }
    &__text{
        position: absolute;
        z-index: 2;
        width: 32%;
        right: 0;
    //    padding-right: 5vw;
    //    display: flex;
    //    align-items: center;
        z-index: 2;
        h2, p{
            color: #fff;
            line-height: 1.3;
        }
        p {
            line-height: 1.7;
        }
        &--duzy-opis{
            width: 69%;
            padding: 3vw 18vw 3vw 5vw;
            h2, p{
                color: black;
            }

            h4 {
                text-transform: initial;
            }
            @include mq($until: xl) {
                padding: 3vw 12vw 3vw 5vw;  
            }
            @include mq($until: lg){
                width: 72%;
                padding: 3vw 6vw 3vw 5vw;  
            }
            @include mq($until: md){
                position: static;
                width: 100%!important;
                padding: 8vw 50px;
            }
            @include mq($until: sm){
                padding: 8vw 30px;
            }
            @include mq($until: xs){
                padding: 8vw 15px;
            }
        }
    }

    &__text-gradient{
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 100%;
        right: 0;
        opacity: .8;
        background: rgb(9,46,24);
        background: linear-gradient(90deg, rgba(9,46,24,0) 0%, rgba(6,38,18,0.6068802521008403) 34%, rgba(0,23,4,1) 100%);
    }

    &__photo{
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;

        &> p {
            width: 100%;
        }

        img {
            height: 80vh;
            object-fit: cover;
        }

        &--opis{

            img {
                width: 30%;
                object-position: left;
                box-shadow: 0px 0px 21px #00000029;
                height: 550px;
                @include mq($until: lg) {
                    width: 28%;
                    height: 580px;
                }

            }     

            @include mq($until: md) {
                img {
                    width: 100%;
                    height: 500px;
                    object-position: bottom;
                }

                p {
                    padding-left: 50px;
                    padding-right: 50px;
                }
            }

            @include mq($until: sm) {
                p {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }

            @include mq($until: xs) {
                p {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }

            @include mq($until: md){
                position: static;
            }
        }

    }

    @include mq($until: sm) {
        &__text {
            width: 50%;
            min-width: 300px;
        }

        img {
            height: 300px;
            object-fit: cover;
        }
    }
}

.fifth{
    padding-bottom: 8vw;

    &__columns{
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
    }
    &__text{
        width: 28%;
        min-width: 250px;
        p{
            margin-bottom: 0;
        }
    }

    &__introduction {
        width: 88%;
        margin: 0 auto;
        h4 {
            text-align: center;
        }
    }

    @include mq($until: md) {
        &__columns {
            flex-direction: column;
            &> div {
                margin-bottom: 25px;

                &:nth-child(2) {
                    margin-left: auto;
                    margin-right: auto;
                }

                &:nth-child(3) {
                    margin-left: auto;
                }
            }
        }
    }

    @include mq($until: xs) {
        &__introduction {
            width: 100%;
            h4 {
                text-align: justify;
                line-height: 1.5;
            }
        }

        &__columns {
            &> div {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

.six{
    padding-bottom: 8vw;
    &__wrapper{
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: center;
        @include mq($until: md){
            flex-direction: column;
        }

    }
    &__left{
        padding: 3vw 5vw 3vw 5vw;
        background-color: #4F647C;
        width: 80%;
        h3{
            margin-top: 0;
            color: #EDEFF2;
            font-family: $secondary-font;
            text-transform: initial;
        }
        p{
            color: #EDEFF2;
        }
        @include mq($until: md){
            width: 100%;
            padding: 5vw 5vw 5vw 5vw;
        }
    }
    &__right{
        padding: 3vw 5vw 5vw 5vw;
        h4{
            margin-top: 0;
            color:#4F647C;
            font-family: $secondary-font;
            text-transform: initial;
        }
        p{
            color: #4F647C;
        }
        @include mq($until: md){
            background-color: #EDEFF2;
            width: 100%;
            padding: 5vw 5vw 3vw 5vw;
        }
    }
    &__bg{
        position: absolute;
        z-index: -1;
        width: 70%;
        height: 110%;
        right: 0;
        background-color: #EDEFF2;
        @include mq($until: md){
           display: none;
        }
    }
}

.seven{
    background-color: #C1BBA9;
    padding-top: 5vw;
    padding-bottom: 5vw;
    min-height: 65vw;
    overflow: hidden;

    h2 {
        color: white;
        font-family: $secondary-font;
        font-size: rem(35px);
        font-weight: 400;
    }

    &--second{
        background-color: unset;

        h2 {
            color: #4F647C;
            font-family: $font-family;
        }
    }
    
    &__wrapper{
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 100%;
    }

    &__head{
        padding-bottom: 5vw;
        padding-left: 16%;
    }

    &__photo{
        position:absolute;

        p img{
            height: 25vw;
            box-shadow: 0px 0px 21px #00000029;
            border: 1px solid #707070;
        }

        &--1{
            position:absolute;
            left: 0;
            z-index: 1;
            top:18vw
        }
        &--2{
            position:absolute;
            z-index: 2;
            right: 45vw;
            // top:10vw

        }
        &--3{
            position:absolute;
            z-index: 3;
            // right: 10vw;
            top:18vw;
            right: 12vw;
        }
        &--4{
            position:absolute;
            // right: 0;
            z-index: 4;
            right: -90px;

        }
    }

    @include mq($until: xl) {
        &__head {
            padding-bottom: 3vw;
        }

        &__wrpper {
            height: 44vw;
        }
    }

    @include mq($until: md) {
        margin-top: 75px;
    }

    @include mq($until: lg) {
        &__photo--4 {
            right: 0;
        }

        &__photo--3 {
            right: 15vw;
        }

        &__photo--2 {
            right: 47vw;
        }

        &__head{
            padding-left: 14.5%;
        }
    }

    @include mq($until: sm) {
        &__photo {
            p img {
                height: 30vw;
            }
        }

        &__wrpper {
            height: 50vw;
        }

        &__photo--2 {
            right: 39vw;
        }
    }

}

.eight{
    h2{
        padding-top: 5vw;
        padding-bottom: 2vw;
    }
    map{
        width: 100%;
    }

    @include mq($until: md) {
        margin-top: 70px;
    }
}

.flatlist-related {
    img {
        margin-top: 50px;
        margin-bottom: 40px;
    }

    @include mq($until: md) {
        margin-top: 100px;
        img {
            display: none;
        } 
    }
}